import styled, { css } from 'styled-components';
import { theme } from '@theme';

import WrImage from '@commons/wr-image';

export const StyledAffiliateDisclosure = styled.div`
    
    background-color: #626565;
    border-radius: 15px;
    color: ${theme.colors.white};
    padding: 12px 12px;
    max-width: 650px;
    margin: 0 auto 25px auto;

    ${theme.mediaQuerys.smUp} {
        display: flex;
        align-items: flex-end;
    }


    ${theme.mediaQuerys.mdUp} {
        margin-bottom: 40px;
    }

    p {
        font-size: 1.2rem;
        line-height: 16px;
        margin: 0;

        ${theme.mediaQuerys.smUp} {
            font-size: 1.4rem;
            line-height: 20px;
        }
    }

    a {
        color: ${theme.colors.white} !important;
        font-size: 1.2rem;
        line-height: 17px;

        ${theme.mediaQuerys.smUp} {
            font-size: 1.4rem;
            line-height: 20px;
        }
    }
`

export const StyledLogo = styled(WrImage)`
    display: block;
    width: 50px;
    margin: 0 0 0 auto !important; // overrides wysiwyg content spacing
    padding: 8px 0 0 5px !important; // overrides wysiwyg content spacing

    ${theme.mediaQuerys.smUp} {
        width: 55px;
        margin: 0 !important; // overrides wysiwyg content spacing
        padding: 0 !important; // overrides wysiwyg content spacing
    }
`