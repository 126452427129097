import React from 'react'
import { StyledAffiliateDisclosure, StyledLogo } from './styles';

import { useStaticQuery, graphql } from "gatsby"

import HtmlParser from '@commons/html-parser';

export default function AffiliateDisclosure({ backgroundColor, textColor, alignFlag, text }) {

    const affiliateDisclosureData = useStaticQuery(
        graphql`
          query {
            wpgraphql {
                header {
                    headerLayout {
                        logo {
                            altText
                            mediaItemUrl

                            imageFile {
                                publicURL
                            }
                        }
                    }
                }

                globalSettings {
                    globalSettingsLayout {
                        affiliateDisclosure
                    }
                }
            }
          }
        `
      )
    
    const { logo = "" } = affiliateDisclosureData.wpgraphql.header.headerLayout
    const { affiliateDisclosure = '' } = affiliateDisclosureData.wpgraphql.globalSettings.globalSettingsLayout
        
    return (
        <StyledAffiliateDisclosure
            $align={alignFlag}
            $backgroundColor={backgroundColor}
            $textColor={textColor}
        >
            <HtmlParser>
                {affiliateDisclosure}
            </HtmlParser>
            
            <StyledLogo
                imageFile={logo.imageFile}
                alt={logo.altText} 
            />
        </StyledAffiliateDisclosure>
    )
}
